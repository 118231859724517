/* Colors */
body {
  background-color: #f8f9f9;
}

.default-header,
.bg-blue,
.custom-control-input:checked~.custom-control-label::before {
  background-color: #00aff0 !important;
}

.bg-green,
.btn-success,
.alert-success,
.Toastify__toast--success {
  background-color: #84bd00 !important;
  border-color: #84bd00 !important;
  color: white;
}

.bg-yellow {
  background-color: #f0c400 !important;
}

.bg-orange,
.btn-warning {
  background-color: #ffa300 !important;
  color: white !important;
}

.bg-red {
  background-color: #ce0058 !important;
}

.bg-beige {
  background-color: #e8bc61;
}

.bg-violet {
  background-color: #9800f0;
}

.card-blue.card-outline {
  border-top: 3px solid #00aff0;
}

.card-green.card-outline {
  border-top: 3px solid #84bd00;
}

.card-yellow.card-outline {
  border-top: 3px solid #f1ff78;
}

.card-orange.card-outline {
  border-top: 3px solid #ffa300;
}

.card-red.card-outline {
  border-top: 3px solid #ce0058;
}

.card.form-container {
  background-color: inherit;
}


a,
a:not(.active)>h5 {
  color: #00aff0;
}

code {
  color: #ce0058;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.text-muted {
  color: silver !important;
}

.text-warning {
  color: #ffa300 !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  /* background-color: ghostwhite; */
  background-color: linen;
}

.table-striped tbody tr:nth-of-type(even) {
  /* background-color: aliceblue; */
  background-color: aliceblue;
}

.table-striped {
  border-collapse: separate;
  border-spacing: 0 5px;
}

tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 10px;
}

tr td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

tr th:first-child {
  padding-left: 10px;
}

.toastify-content {
  background: yellow;
}

.form-control[readonly] {
  background-color: #e9ecefcf;
}

.audit-info {
  background-color: lightyellow;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #f8f9f9;
}

/* Menu */

.navbar-nav .nav-link,
.navbar-nav .dropdown-item {
  text-transform: uppercase;
  white-space: nowrap;
}

.navbar-brand {
  color: skyblue;
  font-weight: 500;
}

.navbar-dark .nav-item.active .nav-link,
.navbar-dark .nav-item .nav-link:active,
.navbar-dark .nav-item .nav-link:focus,
.navbar-dark .nav-item:hover .nav-link {
  /* color: #f1ff78 !important; */
  font-weight: 600;
  color: white !important;
}

/* Tables */

.table thead th {
  background: aliceblue;
  font-weight: 500;
  font-weight: normal;
  color: darkgray;
  text-transform: uppercase;
  white-space: nowrap;
}

table thead .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

table thead .dropup .caret,
table thead .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.grid-actions {
  white-space: nowrap;
}

.grid-actions svg {
  margin-right: 8px;
}

.react-bootstrap-table-pagination {
  margin-top: 10px;
}

.table td {
  border: none;
}

/* Menu */
@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

.dropdown a {
  display: block;
}

/* Various */

.full-width-title {
  text-align: center;
  font-size: 4vw;
  color: rgb(0, 175, 240);
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.transparent-buttons {
  background: none;
  border: none;
  box-shadow: none;
}

.btn-primary {
  color: #fff;
  background-color: rgb(0, 175, 240);
  border-color: rgb(0, 175, 240);
}

.half-width {
  width: 50%;
  text-align: center;
}

.animated-text {
  color: green;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.alert>button {
  float: right;
  position: inherit;
  top: -8px;
}

.pagination.react-bootstrap-table-page-btns-ul {
  justify-content: flex-end;
}

.logo-image {
  max-width: 30px;
  max-height: 30px;
  margin-right: 10px;
  margin-bottom: 5px;
  vertical-align: middle;
}

.custom-radio>label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 400;
}

.card .table {
  margin-bottom: 0;
}

.page-item.active .page-link {
  background-color: rgb(0, 175, 240);
  border-color: rgb(0, 175, 240);
}

.info-box {
  cursor: pointer;
}

.info-box-number {
  white-space: nowrap;
}

.react-bootstrap-table tr:hover {
  cursor: pointer;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: darkgrey;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.nav-link>.label {
  position: relative;
  top: -12px;
  right: 5px;
  text-align: center;
  font-size: 9px;
  padding: 2px 3px;
  line-height: 0.9;
  background-color: green;
}

.btn .badge {
  margin-left: 5px;
}

/* Quill rich text editor */
.ql-container {
  font-size: inherit;
  background-color: #fff;
  margin-bottom: 1em;
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}

.ql-snow.ql-toolbar {
  display: block;
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
}

.ql-editor {
  min-height: 8em;
}

.ql-editor {
  min-height: 8em;
}

.quill.invalid {
  border: 1px solid #dc3545;
  border-radius: 0.25em;
}

.raw-html-block {
  background-color: #e9ecefcf;
  min-height: 5em;
  border: 1px solid #ced4da !important;
}